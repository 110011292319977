export const LitleSmileSvg: React.FC = () => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 28 12"
    fill="none"
  >
    <path
      d="M27.7685 3.97624C25.3312 9.04848 19.8108 12 14.0972 12C8.42187 12 2.93881 9.27297 0.379027 4.27066C0.330241 4.17498 0.281456 4.07837 0.235541 3.98176C0.0327481 3.53462 -0.0476039 3.00099 0.0279652 2.52349C0.0404006 2.33488 0.0834463 2.09106 0.172407 1.92177C0.252759 1.68808 0.368504 1.46543 0.518686 1.26486C1.09358 0.402771 2.18886 -0.0590942 3.23439 0.00622939C4.29427 0.0246304 5.28432 0.607022 5.78843 1.50315C6.35855 2.51429 6.84449 3.45642 7.70349 4.2863C9.35453 5.88167 11.6675 6.7548 14.0025 6.7548C16.3365 6.7548 18.6495 5.88167 20.3006 4.2863C21.105 3.50978 21.6608 2.61917 22.1448 1.63748C22.6231 0.669585 23.6553 0.0255504 24.7697 0.00622939C24.8835 -0.00113101 24.9973 -0.00297111 25.1112 0.00622939C26.0352 0.00622939 26.9879 0.520537 27.4854 1.26486C27.6346 1.46543 27.7503 1.68808 27.8326 1.92177C27.9206 2.09474 27.9627 2.33304 27.977 2.52349C27.9943 2.63389 28 2.74614 28 2.86298C28 3.23928 27.9283 3.63123 27.7685 3.97624Z"
      fill="#0466C8"
    ></path>
  </svg>
);
