import {
  Box,
  Typography,
  Button,
  Container,
  Stack,
  Palette,
  PaletteColor,
  PaletteOptions,
} from "@mui/material";

import safeLink from "./safeLink";

type PaletteColors = {
  [K in keyof Palette]: Palette[K] extends PaletteColor ? K : never;
}[keyof PaletteOptions];

export const ImageSection: React.FC<{
  title: string;
  buttonLabel: string;
  url: string;
  buttonColor?: PaletteColors;
  img: {
    url: string;
    alt: string;
  };
}> = ({ title, img, url, buttonColor, buttonLabel }) => (
  <Container component="section" sx={{ pb: 8 }}>
    <Stack
      aria-label={img.alt}
      direction="column"
      justifyItems="center"
      alignItems="flex-start"
      p={8}
      borderRadius={4}
      sx={{
        backgroundImage: `url(${img.url})`,
        backgroundPosition: "center",
        backgroundSize: "100%",
      }}
    >
      <Typography
        variant="h2"
        color="white"
        fontWeight="bold"
        width={{ xs: "100%", md: "40%" }}
      >
        {title}
      </Typography>
      <Box pt={4}>
        <Button
          color={buttonColor || "primary"}
          variant="contained"
          size="large"
          {...safeLink(url)}
        >
          {buttonLabel}
        </Button>
      </Box>
    </Stack>
  </Container>
);
