import {
  KeyOutlined,
  MeetingRoomOutlined,
  DryCleaningOutlined,
  CleaningServicesOutlined,
  ManageHistoryOutlined,
  PhotoCameraOutlined,
  ConstructionOutlined,
  HeadsetMicOutlined,
  FeaturedVideoOutlined,
} from "@mui/icons-material";
import { Box, Container, Grid, Typography } from "@mui/material";

const ICONS = {
  keys: <KeyOutlined color="primary" fontSize="large" />,
  door: <MeetingRoomOutlined color="primary" fontSize="large" />,
  linen: <DryCleaningOutlined color="primary" fontSize="large" />,
  cleaning: <CleaningServicesOutlined color="primary" fontSize="large" />,
  managment: <ManageHistoryOutlined color="primary" fontSize="large" />,
  photo: <PhotoCameraOutlined color="primary" fontSize="large" />,
  maintenance: <ConstructionOutlined color="primary" fontSize="large" />,
  caller: <HeadsetMicOutlined color="primary" fontSize="large" />,
  advert: <FeaturedVideoOutlined color="primary" fontSize="large" />,
} as const;

interface HappeningProps {
  title: string;
  description: string;
  activities: {
    icon: keyof typeof ICONS;
    description: string;
  }[];
}

export const HappeningsSection: React.FC<HappeningProps> = ({
  activities,
  description,
  title,
}) => (
  <Box component="section" py={12} bgcolor="grey.100">
    <Container>
      <Box px={{ md: 30 }}>
        <Typography
          textAlign="center"
          component="h3"
          variant="h2"
          color="secondary"
          pb={4}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography textAlign="justify">{description}</Typography>
      </Box>
      <Grid container spacing={2}>
        {activities.map((activity, key) => (
          <Grid
            key={key}
            item
            md={4}
            sm={6}
            xs={12}
            mt={8}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {ICONS[activity.icon]}
            <Typography textAlign="center">{activity.description}</Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  </Box>
);
